import React from "react"
import Helmet from "react-helmet"
import { SEO, Layout, Button } from "src/components/core"
import { useTranslation } from "gatsby-plugin-react-i18next" 

import ErrorImg from "src/assets/images/error/404.png"

import "./styles/404.scss"

export default () => {
  const { t } = useTranslation("global")
  return (
    <>
      <Helmet bodyAttributes={{class: 'error-page'}}></Helmet>
      <Layout useLightNav={true}>
        <SEO title="Page Not Found" description="Lone Star National Bank is a community bank with 33 locations in South Texas, United States, including the Rio Grande Valley and San Antonio, Texas." />
        <div className="not-found-page">
          <div className="content-wrap">
            <div className="msg">
              <h1>{t("page_not_found")}</h1>
              <Button to="/">Return Home</Button>
            </div>
            <div className="artwork">
              <img src={ErrorImg} alt="404: Page Not Found" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
